<template>
	<el-dialog
		title="视频回放"
		:visible.sync="visible"
		@open="initVideo"
		@close="handleClose"
	>
		<div>
			<span>选择要播放的源</span>
			<el-select
				v-model="channelIds"
				placeholder="选择播放的源"
				multiple
				style="width:80%;margin-left:5px;"
			>
				<el-option
					v-for="i in 16"
					:key="i"
					:label="`${i}路`"
					:value="i"
				></el-option>
			</el-select>
		</div>
		<div class="videoPlayer">
			<video
				v-for="media in medias"
				:key="media.url"
				:src="media.url"
			></video>
		</div>
	</el-dialog>
</template>

<script>
export default {
	name: "index",
	props: {
		show: Boolean,
		clientId: String,
		startEnd: Array,
	},
	data() {
		return {
			visible: this.show,
			channelIds: [1],
			medias: [],
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
		channelIds() {
			this.initVideo();
		},
	},
	methods: {
		async initVideo() {
			let startTime, endTime;
			if (this.startEnd && this.startEnd.length) {
				startTime = this.startEnd[0];
				endTime = this.startEnd[1];
			}
			this.$http({
				url: "/alert/video/query",
				data: {
					clientId: this.clientId,
					startTime,
					endTime,
					channelIds: this.channelIds.join(","),
				},
			});
		},
		handleClose() {
			this.$emit("close");
		},
	},
	components: {},
};
</script>

<style lang="scss" scoped></style>
