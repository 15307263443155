<template>
	<el-dialog title="事故备案" :visible.sync="visible" @close="handleClose">
		<el-form :model="form" label-position="right">
			<el-form-item label="基本情况" prop="basicInfo">
				<el-input
					v-model="form.basicInfo"
					type="textarea"
					:rows="3"
				></el-input>
			</el-form-item>
			<el-form-item label="简要经过" prop="afterBriefly">
				<el-input
					v-model="form.afterBriefly"
					type="textarea"
					:rows="3"
				></el-input>
			</el-form-item>
			<el-form-item label="经济评估" prop="economicEval">
				<el-input
					v-model="form.economicEval"
					type="textarea"
					:rows="3"
				></el-input>
			</el-form-item>
			<el-form-item label="原因判断" prop="causes">
				<el-input
					v-model="form.causes"
					type="textarea"
					:rows="3"
				></el-input>
			</el-form-item>
			<el-form-item label="控制情况" prop="controlSituation">
				<el-input
					v-model="form.controlSituation"
					type="textarea"
					:rows="3"
				></el-input>
			</el-form-item>
			<el-form-item label="其他" prop="other">
				<el-input
					v-model="form.other"
					type="textarea"
					:rows="3"
				></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="finishAlarm">完成处理</el-button>
				<el-button @click="handleClose">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
export default {
	name: "index",
	props: {
		show: Boolean,
		alarmId: String,
	},
	data() {
		return {
			visible: false,
			form: {
				alertInfoId: "",
				basicInfo: "",
				afterBriefly: "",
				economicEval: "",
				causes: "",
				controlSituation: "",
				other: "",
			},
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
		alarmId() {
			this.form.alertInfoId = this.alarmId;
		},
	},
	methods: {
		reset() {
			this.form = {
				alertInfoId: "",
				basicInfo: "",
				afterBriefly: "",
				economicEval: "",
				causes: "",
				controlSituation: "",
				other: "",
			};
		},
		handleClose() {
			this.reset();
			this.$emit("close");
		},
		async finishAlarm() {
			const result = await this.$http({
				url: "/accidentRecord/addAccidentRecord",
				data: this.form,
			});
			this.$message.success({
				message: result.msg,
				showClose: true,
			});
			this.handleClose();
		},
	},
	components: {},
};
</script>
