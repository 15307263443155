<template>
	<div>
		<el-form ref="checkForm" :model="listQuery" label-width="140px">
			<el-row class="search">
				<el-col :span="18">
					<el-row>
						<el-col :span="6">
							<el-form-item label="报警来源" prop="alertSource">
								<el-select
									placeholder="请选择报警来源"
									v-model="listQuery.alertSource"
								>
                                    <el-option label="一键报警" value="一键报警"></el-option>
                                    <el-option label="场站火警" value="场站火警"></el-option>
                                    <el-option label="全部" value></el-option>
                                </el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="vehicleNo" label="车牌号">
								<el-input
									placeholder="输入车牌号"
									v-model="listQuery.vehicleNo"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="所属机构">
								<selectOrg
									ref="selectOrg"
									@select="selectOrg"
								></selectOrg>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="报警等级" prop="alertLevel">
								<el-select
									placeholder="请选择报警等级"
									v-model="listQuery.alertLevel"
								>
									<el-option
										v-for="i in 10"
										:key="i"
										:label="`${i}级`"
										:value="i"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<el-form-item label="报警类型" prop="alertCode">
								<el-select
									placeholder="请选择报警类型"
									v-model="listQuery.alertCode"
								>
									<el-option
										v-for="code in alertCodes"
										:label="code.alertCode"
										:key="code.typeId"
										:value="code.typeId"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="处理状态" prop="status">
								<el-select
									placeholder="请选择处理状态"
									v-model="listQuery.status"
								>
									<el-option
										label="全部"
										value=""
									></el-option>
									<el-option
										label="未处理"
										value="0"
									></el-option>
									<el-option
										label="已处理"
										value="1"
									></el-option>
									<el-option
										label="处理中"
										value="2"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="生效日期">
								<el-date-picker
									type="daterange"
									v-model="listQuery.startEnd"
									value-format="yyyy-MM-dd HH:mm:ss"
								></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
				</el-col>
				<el-col :span="6">
					<el-row>
						<el-col :span="24">
							<el-form-item>
								<el-button type="primary" @click="search"
									>查询</el-button
								>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="reset"
									>重置</el-button
								>
							</el-form-item>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</el-form>

		<div class="jy_table">
			<ctable
				ref="ctable"
				:columns="columns"
				:data="tableList"
				:total="total"
				operateWidth="200"
				isGenerate
				@cellClick="cellClick"
				@update="initData"
			>
			</ctable>
		</div>

		<handle
			:alarmTime="selData.time"
			:clientId="selData.clientId"
			@close="closeDialog"
			:show="showAlert"
			:dvrId="selData.dvrId"
			:alarmId="selData.id"
		></handle>
		<feedBack
			@close="closeDialog"
			:alarmId="selData.id"
			:show="showFeed"
		></feedBack>
		<detail
			:alarmId="selData.id"
			:show="showDetail"
			@close="closeDialog"
		></detail>
		<replay
			:show="showReplay"
			:clientId="selData.clientId"
			:startEnd="listQuery.startEnd"
		></replay>
	</div>
</template>

<script>
import handle from "@/components/oneKeyAlert";
import feedBack from "./feedBack";
import selectOrg from "@/components/dialog/selectOrg";
import { VUE_BAIDU_MAP_AK } from "@/config/env";
import detail from "./detail";
import replay from "./replay";
import { formatInitTime } from "@/utils"
export default {
	name: "index",
	mounted() {
		this.$nextTick(() => {
			this.initData();
		});
		this.initAlertType();
	},
	methods: {
		search() {
			this.initData();
		},
		reset() {
			this.listQuery = {
				vehicleNo: "",
				alertName: "",
				orgId: "",
				alertSource: "",
				alertLevel: "",
				alertCode: "",
				status: "",
				startEnd: formatInitTime(7,1,true)
			};
			this.$refs.selectOrg.reset();
		},
		initAlertType() {
			this.$http({
				url: "/alert/type/query",
			}).then((res) => {
				this.alertCodes = res.detail.list;
			});
		},
		async initData() {
			const page = this.$refs.ctable.getPager();
			let startTime, endTime;
			if (this.listQuery.startEnd && this.listQuery.startEnd.length) {
				startTime = this.listQuery.startEnd[0];
				endTime = this.listQuery.startEnd[1].$FixedZeroTime();
			}
			const result = await this.$http({
				url: "/alert/handler/queryPage",
				data: {
					...this.listQuery,
					...page,
					startTime,
					endTime,
				},
			});
			result.detail.list.forEach((v) => {
				this.$http({
					baseURL: "/baidu",
					url: "/geocoder",
					method: "get",
					data: {
						location: `${v.latLng.lat},${v.latLng.lng}`,
						output: "json",
						key: VUE_BAIDU_MAP_AK,
					},
				}).then((res) => {
					this.$set(v, "address", res.result.formatted_address);
				});
				v.btnList = this.gernerateBtns(v);
            });
			this.tableList = result.detail.list;
			this.total = result.detail.total;
		},
		closeDialog() {
			this.showDetail = false;
			this.showFeed = false;
			this.showAlert = false;
			this.selData = {};
			this.initData();
		},
		cellClick(index, btnIndex, item) {
			this.selData = item;
			if (btnIndex === 1) {
				this.showAlert = true;
			} else if (btnIndex === 2) {
				this.showDetail = true;
			} else if (btnIndex === 3) {
				this.showFeed = true;
			} else if (btnIndex === 4) {
				this.showReplay = true;
			}
		},
		selectOrg(data) {
			this.listQuery.orgId = data.orgId;
		},
		gernerateBtns(row) {
			let btns = [];
			if (row.status === 0) {
				btns = [
					{
						index: 1,
						title: "处理",
					},
				];
			} else if (row.status === 2) {
				btns = [
					{
						index: 2,
						title: "详情",
					},
					{
						index: 3,
						title: "完成处理",
					},
					{
						index: 4,
						title: "回放",
					},
				];
			} else if (row.status === 1) {
				btns = [
					{
						index: 2,
						title: "详情",
					},
					{
						index: 4,
						title: "回放",
					},
				];
			}
			return btns;
		},
    },
    created() {
        this.listQuery.startEnd = formatInitTime(7,1,true);
    },
	data() {
		return {
			listQuery: {
				vehicleNo: "",
				alertName: "",
				orgId: "",
				alertSource: "",
				alertLevel: "",
				alertCode: "",
				status: "",
				startEnd: [],
			},

			showAlert: false,
			showFeed: false,
			showDetail: false,
			showReplay: false,

			tableList: [],
			columns: [
				{
					prop: "vehicleNo",
					title: "车牌号",
					width: 150,
				},
				{
					prop: "alertName",
					title: "报警名称",
					width: 150,
				},
				{
					prop: "alertLevel",
					title: "报警等级",
					width: 150,
				},
				{
					prop: "alertCode",
					title: "报警类型",
					width: 150,
				},
				{
					prop: "status",
					title: "处理状态",
					width: 150,
					formatter: (index, key, item) => {
						if (item[key] == 0) {
							return "未处理";
						} else if (item[key] === 1) {
							return "已处理";
						} else {
							return "处理中";
						}
					},
				},
				{
					prop: "time",
					title: "报警时间",
					width: 250,
				},
				{
					prop: "address",
					title: "报警位置",
					width: 250,
				},
				{
					prop: "orgName",
					title: "所属单位",
					width: 200,
				},
				{
					prop: "lineNumer",
					title: "车辆线路",
					width: 150,
				},
				{
					prop: "driver",
					title: "司机",
					width: 150,
				},
				{
					prop: "phone",
					title: "联系电话",
					width: 150,
				},
				{
					prop: "handlerMan",
					title: "接警人",
					width: 150,
				},
			],
			total: 0,

			selData: {},

			alertCodes: [],
		};
	},
	components: {
		handle,
		feedBack,
		selectOrg,
		detail,
		replay,
	},
};
</script>
