<template>
  <div style="display:inline-block;">
    <el-input
      v-model="orgName"
      @focus="openOrgDialog"
      placeholder="请选择组织"
      readonly
    ></el-input>
    <el-dialog
      title="选择组织机构"
      :visible.sync="visible"
      @close="handelClose"
      :modal="false"
      width="20%"
      @open="initData"
    >
      <el-tree
        ref="orgTree"
        :props="defaltProps"
        :data="orgData"
        style="height:400px;overflow-y:auto;"
        node-key="id"
        append-to-body
        @node-click="nodeClick"
        default-expand-all
      >
      </el-tree>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "selectOrg",
  props: {
    currentOrgName: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,

      orgData: [],
      defaltProps: {
        label: "text",
        children: "children",
      },
      orgName: this.currentOrgName,
    };
  },
  watch: {
    currentOrgName() {
      this.orgName = this.currentOrgName;
    },
  },
  methods: {
    reset() {
      this.orgName = "";
    },
    async initData() {
      const result = await this.$http({
        url: "/sys/org/tree",
      });
      this.orgData = result.detail;
    },
    openOrgDialog() {
      this.visible = true;
    },
    handelClose() {
      this.visible = false;
    },
    nodeClick(data) {
      this.orgName = data.text;
      this.$emit("select", {
        orgId: data.id,
        orgName: data.text,
        pid: data.pid,
        orgType: data.obj.orgType,
      });
      this.handelClose();
    },
  },
  components: {},
};
</script>
