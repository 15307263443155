<template>
	<el-dialog
		:visible.sync="visible"
		title="一键报警详情"
		@open="initData"
		@close="handleClose"
	>
		<el-row>
			<el-col :span="12">
				<p>车牌号：{{ alertInfo.vehicleNo }}</p>
			</el-col>
			<el-col :span="12">
				<p>车辆线路：K306</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<p>司机姓名：赵大柱</p>
			</el-col>
			<el-col :span="12">
				<p>司机电话：13264198723</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<p>所属单位：{{ alertInfo.orgName }}</p>
			</el-col>
			<el-col :span="12">
				<p>报警位置：{{ alertInfo.address }}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<p>报警时间：{{ alertInfo.time }}</p>
			</el-col>
			<el-col :span="12">
				<p>通知方式：{{ alertInfo.alertType }}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="12">
				<p>报警等级：{{ alertInfo.alertLevel }}级</p>
			</el-col>
			<el-col :span="12">
				<p>报警类型：{{ alertInfo.alertCode }}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<p>通知内容：{{ alertInfo.handlerContent }}</p>
			</el-col>
		</el-row>
		<p>响应部门和人员</p>
		<el-table :data="relatePersons">
			<el-table-column label="序号" prop="userId"></el-table-column>
			<el-table-column label="部门" prop="orgName"></el-table-column>
			<el-table-column label="姓名" prop="userName"></el-table-column>
			<el-table-column label="角色" prop="role"></el-table-column>
			<el-table-column label="联系电话" prop="mobile"></el-table-column>
			<el-table-column label="状态" prop="status"></el-table-column>
		</el-table>
	</el-dialog>
</template>

<script>
export default {
	name: "index",
	props: {
		show: Boolean,
		alarmId: String,
	},
	data() {
		return {
			visible: false,
			alertInfo: {
				address: "",
			},
			relatePersons: [],
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
	},
	methods: {
		async initData() {
			const result = await this.$http({
				url: "/alert/handler/queryDetail",
				data: {
					id: this.alarmId,
				},
			});
			this.alertInfo = result.detail.alertInfo;
			this.relatePersons = result.detail.alertTypeUser;
			this.$http({
				baseURL: "/baidu",
				url: "/geocoder",
				method: "get",
				data: {
					location: `${this.alertInfo.latitude},${this.alertInfo.longitude}`,
					output: "json",
					key: VUE_BAIDU_MAP_AK,
				},
			}).then((res) => {
				this.$set(
					this.alertInfo,
					"address",
					res.result.formatted_address
				);
			});
		},
		handleClose() {
			this.$emit("close");
		},
	},
	components: {},
};
</script>

<style scoped></style>
