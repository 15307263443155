export function formatInitTime( start, end, hms = false, today = false) {
    // start:开始时间前几天，end：结束前几天 hms:是否需要时间 today:包含当天
    let startDate = new Date();
    let endDate = new Date();
    let startTime,endTime;
    if (hms) {
        if (today) {
            endTime = new Date().$Formater("yyyy-MM-dd HH:mm:ss")
        }else{
            endTime = new Date(endDate.setDate( endDate.getDate() - end )).$Formater("yyyy-MM-dd") + " " + "23:59:59"
        }
      startTime = new Date(startDate.setDate( startDate.getDate() - start )).$Formater("yyyy-MM-dd") + " " + "00:00:00"
    }else{
      if (today) {
          endTime = new Date().$Formater("yyyy-MM-dd")
      }else{
          endTime = new Date(endDate.setDate( endDate.getDate() - end )).$Formater("yyyy-MM-dd")
      }
      startTime = new Date(startDate.setDate( startDate.getDate() - start )).$Formater("yyyy-MM-dd")
    }
    return [startTime,endTime]
  }